<template>
  <p :class="[size, color]">
    <slot />
  </p>
</template>

<script setup lang="ts">
const props = defineProps({
  size: {
    type: String as PropType<'sm' | 'md' | 'lg'>,
    default: 'md',
  },
  mode: {
    type: String as PropType<'light' | 'dark'>,
    default: 'light',
  },
})

const sizeMap: { [key: string]: string } = {
  sm: 'tw-font-normal tw-text-sm md:tw-text-base',
  md: 'tw-font-normal tw-text-base',
  lg: 'tw-font-normal tw-text-base md:tw-text-lg',
}

const colorMap: { [key: string]: string } = {
  light: 'tw-text-gray-600',
  dark: 'tw-text-white',
}

const size = computed(() => sizeMap[props.size])
const color = computed(() => colorMap[props.mode])
</script>
